export const ConsigliRg1 = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 p-4">
          <div className="row g-0 overflow-hidden flex-md-row position-relative">
            <div className="col p-4 d-flex flex-column position-static">
              <h2 className="d-inline-block mb-2 text-primary-emphasis">ALCUNI CONSIGLI SULLA COMPRAVENDITA DI FARMACIE</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConsigliRg1;