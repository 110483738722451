import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { InserzioniFarmacieHead } from './InserzioniFarmacieHead';
import { InserzioniFarmacieLista }  from './InserzioniFarmacieLista';
import { HomeRg99 } from './HomeRg99';

export const InserzioniFarmacie = () => {

  return (
    <>
      <Navbar1 />
      {/** 
        <InserzioniFarmacieHead />
        */}
        <HomeRg99 />
      <InserzioniFarmacieLista />
    </>
  )
}
export default InserzioniFarmacie;