import AnchorLink from 'react-anchor-link-smooth-scroll';

export const ComprareUnaFarmaciaToc = () => {
  return (
    <>
          <div className="p-5 bg-body-tertiary rounded">
            <h4 className="fst-italic">Contenuti della pagina</h4>
            <p style={{textAlign: 'left', fontSize: '0.9em', lineHeight: '1.7em'}}>
              <AnchorLink offset='100' href='#Come comprare una farmacia'>- Come comprare una farmacia</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Come Marco ha comprato la sua farmacia'>- La svolta</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Comprare una farmacia con successo'>- Il segreto del successo</AnchorLink>
              </p>          
          </div>
    </>
  )
}

export default ComprareUnaFarmaciaToc;