import AnchorLink from 'react-anchor-link-smooth-scroll';

export const CompravenditaFarmacieToc = () => {
  return (
    <>
          <div className="p-5 bg-body-tertiary rounded">
            <h4 className="fst-italic">Contenuti della pagina</h4>
            <p style={{textAlign: 'left', fontSize: '0.9em', lineHeight: '1.7em'}}>
              <AnchorLink className='my-AncorLink' offset='100' href='#Comprare farmacie'>- Comprare farmacie</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Il supporto giusto'>- Il supporto giusto</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Le tue aspettative'>- Le tue aspettative</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Cosa considerare'>- Cosa considerare</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Vendere farmacie'>- Vendere farmacie</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Affidati a esperti'>- Affidati a esperti</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Perché affidarsi a professionisti'>- Perché affidarsi a professionisti</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Farmacontatto: il partner ideale'>- Farmacontatto: il partner ideale</AnchorLink>
            </p>
          </div>
    </>
  )
}

export default CompravenditaFarmacieToc;