import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { VendereUnaFarmaciaHead } from './VendereUnaFarmaciaHead';
import { VendereUnaFarmaciaRg1 } from './VendereUnaFarmaciaRg1';
import { VendereUnaFarmaciaCorpo } from './VendereUnaFarmaciaCorpo';

export const VendereUnaFarmacia = () => {

  return (
    <>
      <Navbar1 />
      <VendereUnaFarmaciaHead />
      <VendereUnaFarmaciaRg1 />
      <VendereUnaFarmaciaCorpo />
      
    </>
  )
}