
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react"
import { Link as Links } from 'react-scroll';
import { Link } from 'react-router-dom';

export const VendereUnaFarmaciaRg1 = () => {
  return (
    <>
      <div className="my-div">

        <div className='text-sm'>
          <Breadcrumb spacing='8px' >
            <BreadcrumbItem>
              <Link to={`/`}>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Links to={`/`} href='#'>vendere-la-farmacia</Links>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div className="col-12 p-4">
          <div className="col d-flex flex-column position-static" id='Perché scegliere noi'>
            <h2 className="d-inline-block text-primary-emphasis">SEI UN TITOLARE E VUOI VENDERE LA TUA FARMACIA</h2>
            <div className="my-div text-secondary-emphasis mb-4">
              Desideri una valutazione per poter maturare un decisione consapevole?<br></br>Hai già deciso di vendere?
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
export default VendereUnaFarmaciaRg1;