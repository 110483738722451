export const HomeRg99 = () => {
  return (
    <>
      <div className="my-div pt-5">
        <div className="col-12 p-5 pt-5">
            <div className="col d-flex flex-column position-static">
              <h2 className="d-inline-block text-primary-emphasis">FARMACONTATTO TI AIUTA</h2>
              <h2 className="mb-0 text-primary-emphasis mb-2">nella compravendita di farmacie</h2>
              <div className="my-div mb-4">Se vuoi comprare una farmacia, se vuoi vendere una farmacia,
                se vuoi una valutazione della tua farmacia,<br></br> noi ti possiamo fornire tutte le informazioni e i servizi di cui hai bisogno</div>
                <div className="d-grid gap-2 d-md-flex justify-content-center">
                <button type="button" className="btn btn-warning btn-lg">
                <a className='btn-href-black' href="/consigli">Scopri di più</a></button>
              </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default HomeRg99;