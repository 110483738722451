import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { FarmacieInVenditaLista }  from './FarmacieInVenditaLista';
import { FarmacieInVenditaHead } from './FarmacieInVenditaHead';
import { HomeRg1 } from './HomeRg1';

export const FarmacieInVendita = () => {

  return (
    <>
      {/*

      */}
      <Navbar1 />
      <FarmacieInVenditaHead />
      <HomeRg1 />
      <FarmacieInVenditaLista />
    </>
  )
}