import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SideBar } from './SideBar';
import { useParams } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const InserzioniFarmacieLista = () => {
  const { regione } = useParams(); // ← Qui ottieni "Vendita", "Ricerche", "Lombardia", ecc.

  const [titolo, setTitolo] = useState("");
  const [DatiJson, setFarma] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [err, setErr] = useState(null);
  const [seo, setSeo] = useState(""); // SEO testo
  const api_url = `https://org.farmacontatto.it/PhsCrm/inserzioni/esegui/get_inserzioni_web.php?r=${regione}&t=FC`;

  useEffect(() => {
    if (regione === "Ricerche") {
      setTitolo("Inserzioni di ricerca farmacie in vendita");
    } else if (regione === "Vendita") {
      setTitolo("Inserzioni farmacie in vendita");
    } else {
      setTitolo("Inserzioni farmacie in vendita in regione " + regione);
    }

    const getFarma = () => {
      fetch(api_url)
        .then(response => {
          if (response.status >= 400) {
            throw new Error("Si è verificato un errore");
          }
          return response.json();
        })
        .then(data => {
          setFarma(data);
          setIsLoaded(true);
          setSeo(data[0]?.testo_seo || "");
          //    setTitolo(data[0]?.titolo_inserzione || titolo);
        })
        .catch(error => {
          setErr(error);
          setIsLoaded(true);
        });
    };

    getFarma();
  }, [regione]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>{titolo} - Farmacontatto</title>
          <meta name="description" content={seo || `Cerchi una farmacia in vendita in regione ${regione}? ▷ Consulta Farmacontatto.it e contattaci riservatamente.`} />
          <link rel="canonical" href={`https://farmacontatto.it/InserzioniFarmacie/${regione}/`} />
        </Helmet>
      </HelmetProvider>

      <div className='my-div'>
        <div className="row g-2">
          <div className="col-md-9">
            <article className="blog-post p-4"
              style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
              <h1 className="blog-post-meta">{titolo}</h1>

              <div className="row flex-md-row pt-3">
                {!isLoaded && <div>Caricamento...</div>}
                {err && <div>Errore: {err.message}</div>}
                {isLoaded && DatiJson.length > 0 ? (
                  <div className="col-md-12">
                    {DatiJson.map(farma => (
                      <div className="row mb-2" key={farma.rif_farm}>
                        <div className="col-md-12">
                          <div className="card flex-md-row mb-4 box-shadow h-md-250">
                            <div className="d-none d-lg-block">
                            {/*  
                            <Link to={"/farmacia-in-vendita/" + farma.url_pagina}>
                            */}
                            <Link to={`/farmacia-in-vendita/${farma.url_pagina}`}>
                                <img
                                  className='rounded-circle m-3'
                                  src={`../../assets/img/Farmacie-in-vendita-${farma.immagine}.jpg`}
                                  alt={farma.title_immagine}
                                  title={farma.title_immagine}
                                  width="100"
                                  height="100"
                                  loading="lazy"
                                />
                                <br />{farma.regione}
                              </Link>
                            </div>
                            <div className="card-body d-flex flex-column align-items-start">
                              <div className="container">
                                <div className="row justify-content-between">
                                  <div className={`col-xl-4 col-md-6 col-sm-8 my-trattativa-${farma.colore_stato_trattative}`}>
                                    {farma.stato_trattative}
                                  </div>
                                  <div className="col-xl-2 col-md-3 col-sm-4 my-text-dark">
                                    Rif: {farma.rif_farm}
                                  </div>
                                </div>
                              </div>
                            {/*  
                              <Link to={"/farmacia-in-vendita/" + farma.url_pagina}>
                            */}
                            <Link to={`/farmacia-in-vendita/${farma.url_pagina}`}>
                                <h2 className="my-text-dark mt-2">{farma.titolo_inserzione}</h2>
                              </Link>
                              <p className="my-text-dark text-start mb-3">{farma.riassunto_inserzione}</p>
                              <button type="button" className="btn btn-warning btn-md">
                                <Link className='btn-href my-text-dark' to={`https://farmacontatto.it/farmacia-in-vendita/${farma.url_pagina}`}>
                                  Scopri di più
                                </Link>
                                  {/*  
                                <Link className='btn-href my-text-dark' to={"/farmacia-in-vendita/" + farma.url_pagina}>
                                  Scopri di più
                                </Link>
                                   */}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  isLoaded && <div>Inserzioni non trovate!</div>
                )}
                <div className="col-12" id='Comprare una farmacia'></div>
              </div>
            </article>
          </div>

          < SideBar />

        </div>
      </div>
    </>
  );
};

export default InserzioniFarmacieLista;
