import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { SideBar } from './SideBar';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Link } from "react-router-dom";

export const FarmacieInVenditaLista = () => {

  const { regione } = useParams();
  const [DatiJson, setUsers] = useState([])

  const fetchUserData = () => {
    fetch('https://org.farmacontatto.it/PhsCrm/inserzioni/esegui/get_inserzioni_web.php?r=Tutte&t=FC')
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUsers(data)
      })
  }

  useEffect(() => {
    fetchUserData()
  }, [])

  return (
    <>
      {regione == 'Ricerche' &&
        <HelmetProvider>
          <Helmet>
            <title>Inserzioni farmacie in vendita e ricerca farmacie in vendita - Farmacontatto</title>
            <meta name="description" content={'Cerchi una farmacia in vendita in regione ' + regione + '? Ne hai una da vendere o da far valutare? ▷ consulta Farmacontatto e contattaci riservatamente.'} />
            <link rel="canonical" href={'https://farmacontatto.it/InserzioniFarmacie/' + regione + ''} />
    
          </Helmet>
        </HelmetProvider>
      }
      {regione == 'Vendita' &&
        <HelmetProvider>
          <Helmet>
            <title>Inserzioni farmacie in vendita e ricerca farmacie in vendita - Farmacontatto</title>
            <meta name="description" content={'Cerchi una farmacia in vendita in regione ' + regione + '? Ne hai una da vendere o da far valutare? ▷ consulta Farmacontatto e contattaci riservatamente.'} />
            <link rel="canonical" href="https://farmacontatto.it/farmacie-in-vendita/" />
          </Helmet>
        </HelmetProvider>

      }
      <div className='my-div'>
        <div className="row g-2">
          <div className="col-md-9">
            <article className="blog-post p-4"
              style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
              <h1 className="blog-post-meta">
                Inserzioni farmacie in vendita
              </h1>
              <h2>qui troverai inserzioni di farmacie in vendita</h2>
              <div className="row flex-md-row pt-3">
                {DatiJson.length > 0 && (
                  <div className="col-md-12">
                    {DatiJson.map(farma => (

                      <div className="row mb-2" key={farma.url_pagina}>
                        <div className="col-md-12">
                          <div className="card flex-md-row mb-4 box-shadow h-md-250">
                            <Link to={"/farmacia-in-vendita/" + farma.url_pagina}><img className='rounded-circle m-3' src={"../../assets/img/Farmacie-in-vendita-" + farma.immagine + '.jpg'} alt="Farmacie in vendita" title="Farmacie in vendita" width="100" height="100" loading="lazy" /></Link>
                            <div className="card-body d-flex flex-column align-items-start">
                              <div className="container">
                                <div className="row justify-content-between">
                                  <div className={"col-xl-4 col-md-6 col-sm-8 my-trattativa-" + farma.colore_stato_trattative}>{farma.stato_trattative}
                                  </div>
                                  <div className={"col-xl-2 col-md-3 col-sm-4 my-text-dark"}>Rif: {farma.rif_farm}
                                  </div>
                                </div>
                              </div>

                              <Link to={"/farmacia-in-vendita/" + farma.url_pagina}>
                                <h2 className="my-text-dark mt-2" href="#">{farma.titolo_inserzione}
                                </h2>
                              </Link>
                              <p className="my-text-dark text-start mb-3">{farma.riassunto_inserzione}</p>
                              <button type="button" className="btn btn-warning btn-md">
                                <Link className='btn-href my-text-dark' to={"/farmacia-in-vendita/" + farma.url_pagina}>Scopri di più</Link>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    ))}
                  </div>
                )}
                <div className="col-12" id='Comprare una farmacia'>
                </div>
              </div>
            </article>
          </div>
          < SideBar />
        </div>
      </div>
    </>
  );
}

export default FarmacieInVenditaLista;