import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { ConsigliHead } from './ConsigliHead';
import { ConsigliRg1 } from './ConsigliRg1';
import { ConsigliRg2 } from './ConsigliRg2';
import { ConsigliRg3 } from './ConsigliRg3';
import { ConsigliRg4 } from './ConsigliRg4';
import { HomeRg4 } from './HomeRg4';

export const Consigli = () => {

  return (
    <>
      <Navbar1 />
      <ConsigliHead />
      <ConsigliRg1 />
      <ConsigliRg2 />
      <ConsigliRg3 />
      <ConsigliRg4 />

      <HomeRg4 />
    </>
  )
}