import AnchorLink from 'react-anchor-link-smooth-scroll';

export const VendereUnaFarmaciaToc = () => {
  return (
    <>
          <div className="p-5 bg-body-tertiary rounded">
            <h4 className="fst-italic">Contenuti della pagina</h4>
            <p style={{textAlign: 'left', fontSize: '0.9em', lineHeight: '1.7em'}}>
              <AnchorLink offset='100' href='#Vendere la tua farmacia'>- Vendere la farmacia</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Come vendere la farmacia'>- Come vendere la farmacia</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Farlo nel modo giusto'>- Farlo nel modo giusto</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Quando è il momento giusto'>- Quando è il momento giusto</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Affidarsi a professionisti'>- Affidarsi a professionisti</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#La riservatezza è fondamentale'>- La riservatezza è fondamentale</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Collaboriamo con diverse agenzie'>- Collaboriamo con diverse agenzie</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Uno dei maggiori ostacoli'>- Uno dei maggiori ostacoli</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Mediatori abilitati'>- Mediatori abilitati</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Farmacontatto: il supporto ideale'>- Farmacontatto: il supporto ideale</AnchorLink>
            </p>
          </div>
    </>
  )
}
export default VendereUnaFarmaciaToc;