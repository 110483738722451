
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react"
import { Link as Links } from 'react-scroll';
import { Link } from 'react-router-dom';

export const CompravenditaFarmacieRg1 = () => {
  return (
    <>
      <div className="my-div">
        <div className='text-sm'>
          <Breadcrumb spacing='8px' >
            <BreadcrumbItem>
              <Link to={`/`}>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Links to={`/`} href='#'>CompravenditaFarmacie</Links>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div className="col-12 p-4">
          <div className="col d-flex flex-column position-static">
            <h2 className="d-inline-block text-primary-emphasis">CI OCCUPIAMO DI COMPRAVENDITA DI FARMACIE</h2>
            <div className="my-div text-secondary-emphasis mb-4">
              Ti possiamo aiutare a portare a termine la delicata operazione di compravendita supportandoti in ogni sua fase
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompravenditaFarmacieRg1;