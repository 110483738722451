
import Il_momento_di_vendere_la_farmacia from './Immagini/Il-momento-di-vendere-la-farmacia.png';
import Farmacia_venduta from './Immagini/Farmacia-venduta.png';

import { VendereUnaFarmaciaSb } from './VendereUnaFarmaciaSb';
import ContattiLink from './ContattiLink';
import ContattiBottone from './ContattiBottone';
import { HomeRg4 } from './HomeRg4';

import user_friends_solid from './icone/user-friends-solid.svg';
import newspaper_solid from './icone/newspaper-solid.svg';
import handshake_solid from './icone/handshake-solid.svg';
import sellsy from './icone/sellsy.svg';
import user_secret_solid from './icone/user-secret-solid.svg';
import money_bill_1_regular from './icone/money-bill-1-regular.svg';

export const VendereUnaFarmaciaCorpo = () => {

  return (
    <>
      <div className='my-div'>
        <div className="row g-2">
          <div className="col-md-9">

            <article className="blog-post pt-3 p-3"
              style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
              <p className="blog-post-meta"> Marzo, 2025 di
                <ContattiLink nome=' Achille Bonzini' />
              </p>
              <div className="row flex-md-row p-3">
                <div className="col-md-6 col-lg-4 order-sm-2" id='Vendere la tua farmacia'>
                  <img src={Il_momento_di_vendere_la_farmacia} className="d-block mx-lg-auto img-fluid" alt="E' momento di vendere la farmacia?" title="E' momento di vendere la farmacia?" width="650" loading="lazy" />
                </div>
                <div className="col-md-6 col-lg-8 order-sm-1 p-4" id='Come vendere la farmacia'>
                  <p style={{ color: '#f57e20', fontWeight: 'bold' }}>COME VENDERE LA FARMACIA</p>
                  <div style={{ textAlign: 'left' }} id='Noi ti aiutiamo vendere la farmacia'>
                    <h3>Noi ti aiutiamo vendere la farmacia</h3>
                    <br></br>
                    <p className="my-citazione" id='Farlo nel modo giusto'>
                      Vendere la farmacia: come farlo nel modo giusto
                    </p>
                    <p>
                      Se stai valutando di vendere la farmacia, ma non sai ancora se sia il momento giusto o quale sia la strategia
                      migliore, siamo qui per aiutarti.
                    </p>
                    <p>
                      La vendita di una farmacia è un'operazione complessa che richiede attenzione,
                      competenza e il supporto di professionisti esperti del settore.
                    </p>
                    <p className="my-citazione" id='Quando è il momento giusto'>
                      Quando è il momento giusto per vendere la farmacia?
                    </p>
                    <p>
                      Se sei vicino alla pensione o senti di non avere più lo stesso entusiasmo nella gestione della tua attività,
                      potrebbe essere il momento di valutare la vendita.
                    </p>
                    <p>
                      Personalmente credo che non convenga “tirare a campare” ancora un po’, come sento spesso dire,
                      rimandando la decisione.
                      Continuare a gestire la farmacia con meno impegno, o anche solo meno bene del solito, potrebbe
                      influire negativamente sul valore dell’attività.
                    </p>
                    <p>
                      Noi di Farmacontatto possiamo aiutarti a vendere la tua farmacia alle migliori condizioni, garantendo la
                      massima riservatezza durante tutto il processo di trattativa.
                    </p>
                  </div>
                </div>

                <div className="col-md-6 col-lg-4 order-md-3 p-4" id='Comprare una farmacia'>
                  <img src={Farmacia_venduta} className="d-block mx-lg-auto img-fluid" alt="Ho venduto la farmacia" title="Ho venduto la farmacia" width="720" loading="lazy" />
                </div>
                <div className="col-md-6 col-lg-8 order-sm-3 px-4">

                  <p className="mb-3 text-start" id='Questo è un buon momento'>

                    <p className="my-citazione" id='Affidarsi a professionisti'>
                      Perché affidarsi a professionisti per vendere la farmacia?
                    </p>
                    <p>
                      Affidarsi a esperti del settore significa ottenere:
                    </p>
                    <ul>
                      <li>
                        - Una <b>valutazione accurata</b> della farmacia per stabilire il giusto prezzo di mercato
                      </li>
                      <li>
                        - Un’analisi del <b>carico fiscale</b> e del guadagno netto effettivo
                      </li>
                      <li>
                        - Una <b>selezione mirata</b> dei potenziali acquirenti, tutelando la tua privacy
                      </li>
                    </ul>
                  </p>
                  <p className="mb-3 text-start">
                    Questo è un buon momento per vendere e ti consiglio di leggere l’articolo sull’Osservatorio di Pharmascout <a className='my-link' href='https://pharmascout.it/comprare-o-vendere-la-farmacia/' target='blanks'>“E’ il momento di comprare o vendere la farmacia?“</a>
                  </p>
                  <p className="mb-3 text-start">
                    Grazie al nostro portale <a className='my-link' href='https://pharmascout.it' target='blanks'>Pharmascout</a> e alla collaborazione con agenzie specializzate, riceviamo costantemente richieste di acquisto da tutta Italia, da farmacisti e investitori interessati al settore.
                  </p>

                </div>



              </div>
              <div>
                <div className="col-12 px-4">
                  <p className="mb-3 text-start">
                    <p className="my-citazione" id='La riservatezza è fondamentale'>
                      La riservatezza è fondamentale
                    </p>
                    <p>
                      Uno dei rischi principali nella <b>vendita di una farmacia</b> è la fuga di informazioni. Una diffusione prematura della
                      notizia potrebbe creare incertezze tra i dipendenti, i fornitori e le banche, compromettendo la stabilità
                      ed il valore della farmacia stessa.
                    </p>
                    <p>
                      Per questo motivo, operiamo con la massima discrezione, abbiamo creato il nostro portale di inserzioni <a className='my-link' href='https://pharmascout.it' target='blanks'>Pharmascout</a> ed evitiamo strumenti poco adatti come i social network,
                      dove la divulgazione non controllata di informazioni potrebbe danneggiare la tua attività.
                    </p>
                  </p>
                  <p className="mb-3 text-start">
                    <p className="my-citazione" id='Collaboriamo con diverse agenzie'>
                      Collaboriamo con diverse agenzie (se serve)
                    </p>
                    <p>
                      <b>Previo accordo con il venditore</b>, collaboriamo con diverse agenzie di intermediazione specializzate in
                      farmacie e distribuite su tutto il territorio nazionale.
                      Con loro condividiamo incarichi di farmacie in vendita, informazioni sul mercato, metodi e regole
                      interne, soprattutto quelle relative alla riservatezza.
                    </p>
                    <p className="my-citazione" id='Uno dei maggiori ostacoli'>
                      Uno dei maggiori ostacoli
                    </p>
                    <p>
                      Sembrerà forse strano a qualcuno, ma uno dei maggiori ostacoli al buon esito di una trattativa per una
                      farmacia in vendita è la fuga di notizie che si verifica quando vengono coinvolte, nella gestione delle
                      trattative, persone che non mantengono la necessaria riservatezza.
                      Sono in genere persone che non si occupano specificatamente di vendita di farmacie e che non sanno come
                      si opera in questo mercato o che, addirittura, non sono nemmeno mediatori abilitati.
                    </p>
                    <p className="my-citazione" id='Mediatori abilitati'>
                      Mediatori abilitati
                    </p>
                    <p>
                      Ricordiamo che il mediatore abilitato è <b>iscritto nella apposita sezione della Camera di Commercio</b> (lo puoi con trollare!) e
                      che il suo è <b>un lavoro esclusivo</b>, cioè, non può fare nessun altro lavoro, non può essere un
                      rappresentante, un avvocato, un commercialista, un direttore di banca ecc…
                    </p>
                    <p>
                      Sappi inoltre che non ti conviene rendere pubbliche certe tue faccende personali ed ecco perché certi
                      canali, e soprattutto i social network, non sono adatti a questo genere di cose.
                      Intendiamoci …questi ultimi sono programmi di enorme diffusione e successo planetario e
                      sono gratuiti, ma sono concepiti e realizzati per altri scopi.
                      <br></br>
                      Citando Jason Lanier, inventore della realtà virtuale, <b>"Quando il servizio è gratis, il prodotto sei tu"</b>.
                    </p>
                    <p>
                      Inoltre, venendo a sapere o leggendo sui social che vuoi vendere la farmacia, i tuoi dipendenti,
                      allarmati, inizierebbero a mandare curriculum in tutte le farmacie della zona. Le altre implicazioni
                      con fornitori e banche sarebbero ancora più gravi e pesanti, se vuoi te le posso elencare di persona.
                    </p>
                    <p className="mb-3 text-start">
                      Pensi che tutto ciò sia bene per te? Secondo noi no; ecco perché ti diamo la possibilità di gestire, con il nostro aiuto, la trattativa in forma sicura e anonima.
                    </p>

                    <p className="my-citazione" id='Farmacontatto: il supporto ideale'>
                      Farmacontatto: il supporto ideale per vendere la farmacia
                    </p>
                    <p>
                      Se vuoi <b>vendere la farmacia</b> in modo sicuro e vantaggioso, contattaci riservatamente.
                      Ti guideremo in ogni fase della trattativa, garantendo la massima professionalità e riservatezza.
                    </p>
                  </p>
                  <p className="mb-3 text-start">
                    Scrivimi cosa ne pensi o per ricevere maggiori informazioni.
                  </p>
                  <p className='text-start'><b>
                    Achille Bonzini
                  </b>
                  </p>
                </div>
                <div className="row">
                  <div className="col-12 pt-4"
                    style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}
                  >
                    <div className="row g-0 overflow-hidden flex-md-row">
                      <div className="col d-flex flex-column position-static" id='Noi compriamo la tua farmacia'>

                        <h2 className="d-inline-block mb-2 text-primary-emphasis">E TU?</h2>
                        <h3 className="mb-0 text-primary-emphasis mb-3">Vorresti vendere la tua farmacia o ne vorresti comprare una?
                          <br></br>Perché dovresti scegliere noi e non altri?</h3>

                        <p className="card-text mb-auto">
                          <ContattiBottone etichetta=' Scrivimi in privato' />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </article>

            <div className="row pt-3 mb-5 p-5" style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
              <div className="row">

                <div className="col-lg-4">
                  <img src={handshake_solid} alt="Siamo intermediari e ci occupiamo di farmacie" width="50" height="50" />
                  <h3>Siamo intermediari e ci<br></br>
                    occupiamo di farmacie</h3>
                  <p>Siamo agenti intermediari abilitati, ci occupiamo solo di farmacie e lo facciamo tutti i giorni.</p>
                </div>

                <div className="col-lg-4">
                  <img src={user_friends_solid} alt="Farmacontatto- Collaboriamo con altre agenzie selezionate" width="50" height="50" />
                  <h3>Collaboriamo con altre agenzie selezionate</h3>
                  <p>Affidare a noi un incarico è come affidarsi a più agenti specializzati che collaborano tra loro.</p>
                </div>

                <div className="col-lg-4">
                  <img src={newspaper_solid} alt="Pharmascout: il nostro portale multi-agenzia" width="50" height="50" />
                  <h3>Pharmascout: il nostro portale multi-agenzia</h3>
                  <p>Su pharmascout.it trovi le inserzioni di farmacie in vendita delle agenzie aderenti al gruppo.</p>
                </div>


              </div>
              <div className="row">
                <div className="col-lg-4">
                  <img src={sellsy} alt="Farmacontatto - Valutazioni gratuite e perizie" width="50" height="50" />
                  <h3>Valutazioni e perizie</h3>
                  <p>Se vuoi sapere quanto vale la tua farmacia, contattaci nella massima riservatezza.</p>
                </div>

                <div className="col-lg-4">
                  <img src={user_secret_solid} alt="Farmacontatto - Trattative nella massima riservatezza" width="50" height="50" />
                  <h3>Massima riservatezza</h3>
                  <p>La riservatezza è la prima regola che ci siamo dati; la rispettiamo e la facciamo rispettare sempre.</p>
                </div>

                <div className="col-lg-4">
                  <img src={money_bill_1_regular} alt="Farmacontatto - Richiesta finanziamenti" width="50" height="50" />
                  <h3>Richiesta finanziamenti</h3>
                  <p>Aiutare i compratori ad ottenere un finanziamento significa agevolare la vendita al meglio della tua farmacia.</p>
                </div>
              </div>
              <HomeRg4 />
            </div>
          </div>
          < VendereUnaFarmaciaSb />
        </div >
      </div >

    </>
  )
}
export default VendereUnaFarmaciaCorpo;