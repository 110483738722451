import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { FarmaciaInVenditaCorpo }  from './FarmaciaInVenditaCorpo';

export const FarmaciaInVendita = () => {

  return (
    <>
      <Navbar1 />
      <FarmaciaInVenditaCorpo />
    </>
  )
}
export default FarmaciaInVendita;