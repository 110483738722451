import './App.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Home } from './components/Home';

import { Chisiamo } from './components/ChiSiamo';

import { Consigli } from './components/Consigli';
import { VendereUnaFarmacia } from './components/VendereUnaFarmacia';
import { ComprareUnaFarmacia } from './components/ComprareUnaFarmacia';
import { CompravenditaFarmacie } from './components/CompravenditaFarmacie';

import { InserzioniFarmacie } from './components/InserzioniFarmacie';
import { FarmacieInVendita } from './components/FarmacieInVendita';
import { FarmaciaInVendita } from './components/FarmaciaInVendita';

import { Condizioni } from './components/Condizioni';
import { Cookies } from './components/Cookies';
import { Privacy } from './components/Privacy';

import { Footer } from './components/Footer';

import { NotFound } from './components/NotFound';

import { NonDisponibile } from './components/NonDisponibile';

import  ScrollToTop from './components/ScrollToTop';

  function App() {
    
    return (
      <BrowserRouter>
      <div className="App">
        <div>
          <Routes>

            {/*
          import { DettaglioRegione } from './components/DettaglioRegione';
          import { InserzioniFarmacie } from './components/InserzioniFarmacie';
          import { InserzioniFarmacieRicerche } from './components/InserzioniFarmacieRicerche';
          
          <Route path="/" element={<ListaRegioni />} />
          <Route path='InserzioniFarmacie/:regione' element={<DettaglioRegione />} />
          <Route path='inserzioni-farmacie-ricerche' element={<InserzioniFarmacieRicerche />} />
          <Route path="/regione/:nome" element={<DettaglioRegione />} />
          <Route path='InserzioniFarmacie' element={<InserzioniFarmacie />} />
          */}

            <Route path='/' element={<Home />} />
            <Route path='vendere-la-farmacia' element={<VendereUnaFarmacia />} />
            <Route path='comprare-una-farmacia' element={<ComprareUnaFarmacia />} />
            <Route path='compravendita-farmacie' element={<CompravenditaFarmacie />} />

            <Route path='cookies' element={<Cookies />} />
            <Route path='condizioni' element={<Condizioni />} />
            <Route path='privacy' element={<Privacy />} />
            <Route path='farmacie-in-vendita' element={<FarmacieInVendita />} />

            <Route path='inserzioni-farmacie/:regione' element={<InserzioniFarmacie />} />

            <Route path='chi-siamo' element={<Chisiamo />} />
            <Route path='consigli' element={<Consigli />} />
            <Route path='farmacia-in-vendita/:url_pagina' element={<FarmaciaInVendita />} />
            <Route path='*' element={<NotFound />} />
            <Route path='non-disponibile' element={<NonDisponibile />} />

          </Routes>
        </div>
        <ScrollToTop />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
