import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { ComprareUnaFarmaciaHead } from './ComprareUnaFarmaciaHead';
import { ComprareUnaFarmaciaRg1 } from './ComprareUnaFarmaciaRg1';
import { ComprareUnaFarmaciaCorpo } from './ComprareUnaFarmaciaCorpo';

export const ComprareUnaFarmacia = () => {

  return (
    <>
      <Navbar1 />
      <ComprareUnaFarmaciaHead />
      <ComprareUnaFarmaciaCorpo />
      <ComprareUnaFarmaciaRg1 />
    </>
  )
}
export default ComprareUnaFarmacia;