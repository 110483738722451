import Pharmascout from './Immagini/Pharmascout-farmacie-in-vendita-m.jpg';

export const HomeRg5 = () => {
  return (
    <>

      <div>

        <div className="container-fluid g-0" style={{
          backgroundImage: `url(${Pharmascout})`,
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: "center",
          backgroundPositionY: "center",
          title: "Farmacontatto",
          alt: "Farmacontatto"
        }}>
          <div className="container-fluid" id='Farmacontatto'>
            <div className="col p-5 row justify-content-md-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', height: 'auto', color: 'white' }}>

              <div className="col-md-8 col-lg-6 rounded-pill" style={{
                paddingTop: '2em',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                height: '25em',
                color: 'white'
              }}>
                <h2 className="display-2 pt-3" style={{
                  fontFamily: 'Roboto, Sans-serif',
                  fontSize: '2em',
                  color: '#f57e20',
                  fontWeight: 'bold',
                  stroke: '#000',
                  strokeWidth: '1px',
                  textShadow: '2px 3px 2px #222222'
                }}>
                  Le nostre inserzioni<br></br> le trovi anche su:</h2>
                <div>
                  <h2 style={{
                    paddingTop: '0.5em',
                    fontFamily: 'Open Sans, Sans-serif',
                    fontSize: '2.2em',
                    color: '#eb3b3b',
                    fontWeight: '700',
                    WebkitTextStrokeWidth: '1px',
                    WebkitTextStrokeColor: 'black',
                  }}>
                    PHARMASCOUT.IT
                  </h2>

                  <h3 style={{
                    fontFamily: 'Open Sans, Sans-serif',
                    fontSize: '1.4em',
                    color: '#ffffff',
                    fontWeight: '500',
                  }}>
                    il portale multi-agenzia di Farmacontatto
                  </h3>

                  <h3 style={{
                    fontFamily: 'Open Sans, Sans-serif',
                    fontSize: '1em',
                    color: '#ffffff',
                    fontWeight: '500',
                    paddingBottom: '1em'
                  }}>
                    dove trovi i nostri annunci di farmacie in vendita ...e non solo
                  </h3>
                  <button type="button" className="btn btn-warning btn-lg px-4 me-md-2"
                    style={{
                      backgroundColor: "#f57e20",
                      color: "#ffffff"
                    }}>
                    <a className='btn-href-black' href="https://pharmascout.it" target="_blank">Vai su Pharmascout.it</a>
                  </button>
                </div>
              </div>
            </div>

          </div>
        </div>


      </div>

    </>
  )
}

export default HomeRg5;





