import { CompravenditaFarmacieToc } from './CompravenditaFarmacieToc';
import { AltrePagineSb } from './AltrePagineSb';
import { PharmascoutSb } from './PharmascoutSb';

export const CompravenditaFarmacieSb = () => {
  return (
    <>
      <div className="col-md-3">
        <div className="position-sticky" style={{ top: '2rem' }}>

          <CompravenditaFarmacieToc />
          <AltrePagineSb />
          <PharmascoutSb />

        </div>
      </div>
    </>
  )
}
export default CompravenditaFarmacieSb;