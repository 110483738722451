import React from "react";
import "./css/blog.css";
import { Link as Links } from 'react-scroll';
import { Navbar1 } from './navbar1';
import { ChisiamoHead } from './ChisiamoHead';
import { ChisiamoRg1 } from './ChisiamoRg1';
import { ChisiamoRg2 } from './ChisiamoRg2';
import { ChisiamoRg3 } from './ChisiamoRg3';
import { HomeRg4 } from './HomeRg4';

export const Chisiamo = () => {

  return (
    <>
      <Navbar1 />
      <ChisiamoHead />
      <ChisiamoRg1 />
      <ChisiamoRg2 />
      <ChisiamoRg3 />
      <HomeRg4 />
    </>
  )
}