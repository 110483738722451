import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { CompravenditaFarmacieHead } from './CompravenditaFarmacieHead';
import { CompravenditaFarmacieRg1 } from './CompravenditaFarmacieRg1';
import { CompravenditaFarmacieCorpo } from './CompravenditaFarmacieCorpo';

export const CompravenditaFarmacie = () => {

  return (
    <>
      <Navbar1 />
      <CompravenditaFarmacieHead />
      <CompravenditaFarmacieRg1 />
      <CompravenditaFarmacieCorpo />
    </>
  )
}