import Pharmascout_logo from './Immagini/Pharmascout-logo.jpg';
import Pharmascout_osservatorio_logo from './Immagini/Pharmascout-osservatorio-logo.jpg';

export const PharmascoutSb = () => {
  return (
    <>
      <div className="p-4">
        <h4 className="fst-italic">Su Pharmascout.it</h4>
        <ul className="list-unstyled">
              <li>
                <a className="d-flex flex-column flex-lg-row gap-2 align-items-center py-3 link-body-emphasis text-decoration-none border-top" href="https://pharmascout.it" target="_blank">
                  <img src={Pharmascout_logo} className="d-block mx-lg-auto img-fluid" alt="Compravendita farmacie" title="Compravendita farmacie" width="120" loading="lazy" />
                  <div className="col-lg-6">
                    <h6 className="mb-0"><b>Pharmascout.it</b><br></br><br></br>Inserzioni farmacie in vendita</h6>
                  </div>
                </a>
              </li>
              <li>
                <a className="d-flex flex-column flex-lg-row gap-2 align-items-center py-3 link-body-emphasis text-decoration-none border-top" href="https://pharmascout.it/osservatorio-farmacie-pharmascout/" target="_blank">
                  <img src={Pharmascout_osservatorio_logo} className="d-block mx-lg-auto img-fluid" alt="Comprare una farmacia" title="Comprare una farmacia" width="120" loading="lazy" />
                  <div className="col-lg-6">
                    <h6 className="mb-0"><b>Pharmascout.it</b><br></br><br></br>Osservatorio farmacie</h6>
                  </div>
                </a>
              </li>
            </ul>
      </div>
    </>
  )
}

export default PharmascoutSb;