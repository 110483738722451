import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const RegioniSb = () => {
  const [DatiJson, setDatiJson] = useState([]);
  const [DatiTotale, setDatiTotale] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch Totali e Regioni in parallelo
    const fetchData = async () => {
      try {
        const [totaleRes, regioniRes] = await Promise.all([
          fetch("https://org.farmacontatto.it/PhsCrm/inserzioni/esegui/get_inserzioni_web.php?r=Tot&t=FC"),
          fetch("https://org.farmacontatto.it/PhsCrm/inserzioni/esegui/get_inserzioni_web.php?r=Reg&t=FC")
        ]);

        const totaleData = await totaleRes.json();
        const regioniData = await regioniRes.json();

        setDatiTotale(Array.isArray(totaleData) ? totaleData : []);
        setDatiJson(Array.isArray(regioniData) ? regioniData : []);
      } catch (error) {
        console.error("Errore nel fetch dei dati sidebar:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h4 className="fst-italic pt-2">Inserzioni Farmacie</h4>
      <div className="row flex-md-row pt-1">
        {!loading && (DatiTotale.length > 0 || DatiJson.length > 0) ? (
          <>
            {/* Totali */}
            {DatiTotale.length > 0 && (
              <div>
                {DatiTotale.map((ftot, index) => (
                  <div key={index} className="col-md-12">
                    <div className="row mb-1">
                      <div className="col-md-12 text-center">
                        <Link to="/farmacie-in-vendita">
                          Totale inserzioni ({ftot.totale})
                        </Link>
                      </div>
                      <div className="col-md-12 text-center">
                        <Link to="/inserzioni-farmacie/Vendita">
                          Farmacie in Vendita ({ftot.vendite})
                        </Link>
                      </div>
                      <div className="col-md-12 text-center">
                        <Link to="/inserzioni-farmacie/Ricerche">
                          Ricerca Farmacie ({ftot.ricerche})
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {/* Regioni */}
            {DatiJson.length > 0 && (
              <>
                <h4 className="fst-italic pt-2">Per Regione</h4>
                <div>
                  {DatiJson.map((farma, index) => (
                    <div className="col-md-12" key={index}>
                      <div className="row mb-1">
                        <div className="col-md-12 text-center">
                          <Link to={`/inserzioni-farmacie/${farma.regione}`}>
                            {farma.regione} ({farma.numero})
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        ) : loading ? (
          <div className="col-md-12 text-center">Caricamento...</div>
        ) : (
          <div className="col-md-12 text-center">Nessuna inserzione disponibile</div>
        )}

        <div className="col-12" id="Comprare una farmacia"></div>
      </div>
    </div>
  );
};

export default RegioniSb;