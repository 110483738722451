import { Ritorna } from './Ritorna';
import { PharmascoutSb } from './PharmascoutSb';
import { RegioniSb } from './RegioniSb';
import { AltrePagineSb } from './AltrePagineSb';

export const SideBar = () => {
  return (
    <>
      <div className="col-md-3">
        <div className="position-sticky" style={{ top: '2rem' }}>
          <Ritorna />
          <RegioniSb />
          <AltrePagineSb />
          <PharmascoutSb />
        </div>
      </div>
    </>
  )
}

export default SideBar;