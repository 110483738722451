
import Farmacontatto_compravendita_farmacie from './images/Donna-Farmacista.jpg';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ContattiBottone from './ContattiBottone';


export const HomeHead = () => {
  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>Farmacontatto - Copravendita di Farmacie</title>
          <meta name="description" content="Farmacontatto è un'agenzia d'intermediazione specializzata in compravendita di farmacie, ricerca farmacie, valutazione farmacie. Noi acquistiamo farmacie nella massima riservateza."/>
        </Helmet>
      </HelmetProvider>

      <div className="mycontainer">
        <div className="px-5 text-center border-bottom" id='Compravendita di farmacie' style={{ backgroundColor: "#daecf4" }}>
          <div className="row flex-lg-row-reverse align-items-center ">
            <div className="col pt-5 col-sm-12 col-lg-6">
              <img src={Farmacontatto_compravendita_farmacie} className="d-block mx-lg-auto img-fluid" alt="Bootstrap Themes" width="700" height="500" loading="lazy" />
            </div>
            <div className="col-lg-6 mb-3"
              style={{
                borderRadius: '20px',
                backgroundColor: '#f7f9fc',
                padding: '20px'
              }}>
              <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3"
                style={{
                  textShadow: '-3px 3px 6px #A39090',
                  fontSize: '5VW',
                  WebkitTextStrokeWidth: '1px',
                  WebkitTextStrokeColor: '#54595f'
                }}>
                <font color="#5672AD">Farma</font><font color="#8fc963">con</font><font color="#5672AD">tatto</font></h1>
              <p className="lead fw-bold"
                style={{
                  textShadow: '-1px 1px 2px #A39090',
                  fontSize: '2.2VW',
                }}>
                <font color="#5672AD">
                  Se stai cercando una farmacia in vendita, sei nel posto giusto, noi ti possiamo aiutare
                </font>
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-center">
              <ContattiBottone etichetta='Contattaci' />
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default HomeHead;