import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { HomeHead } from './HomeHead'
import { HomeRg1 } from './HomeRg1';
import { HomeRg2 } from './HomeRg2';
import { HomeRg3 } from './HomeRg3';
import { HomeRg4 } from './HomeRg4';
import { HomeRg5 } from './HomeRg5';

export const Home = () => {

  return (
    <>
      <Navbar1 />
      <HomeHead />
      <HomeRg1 />
      <HomeRg2 />
      <HomeRg3 />
      <HomeRg5 />
          {/*
           */} 
      <HomeRg4 />
    </>
  )
}

export default Home;